<template>

  <div class="home-resolve-list">


    <div
        class="home-resolve-cell"
        v-if="whitePaper.productName!=null"
    >
      <img :src="whitePaper.productLogo" alt="" />
      <div>
        <p class="title">{{ whitePaper.productName }}</p>
        <p class="desc">发布日期：{{ formatDateTime(whitePaper.createTime) }}</p>
        <p class="desc">{{ whitePaper.productIntroduction }}</p>
        <table>
          <tr>
            <td class="left-column">白皮书</td>
            <td class="right-column">

              <div class="op1">
              <span @click="gotoDetail(whitePaper.productId)">
                下载 <i class="fa fa-cloud-download" />
              </span>
              </div>

            </td>
          </tr>
        </table>

      </div>
    </div>




    <div
        v-for="(value, key) in versionCompanyProductMap"
        :key="key"
        class="home-resolve-cell"
    >
      <template v-if="value!=null&&value[0]!=null">
      <img :src="value[0].productLogo" alt="" />
      <div>
        <p class="title">{{ value[0].productName }}</p>
        <p class="desc">发布日期：{{ formatDateTime(value[0].createTime) }}</p>
        <p class="desc">{{ value[0].productIntroduction }}</p>
        <table>
          <tr v-for="item in value" :key="item.id">
            <td class="left-column">{{item.moduleName}}</td>
            <td class="right-column">

              <div class="op1">
              <span v-if="item.installFileId!=null"  @click="gotoDetail(item.installFileId)">
                安装包 <i class="fa fa-cloud-download" />
              </span>
                <span v-if="item.sourceCodeId!=null" @click="gotoDetail(item.sourceCodeId)">
                源代码 <i class="fa fa-cloud-download" />
              </span>
                <span v-if="item.documentId!=null" @click="gotoDetail(item.documentId)">
                技术文档 <i class="fa fa-cloud-download" />
              </span>
              </div>

            </td>
          </tr>
        </table>

      </div>
      </template>
    </div>

  </div>
</template>

<script>

import {getAllCompanyProduct, getWhitePaper} from "@/api/product";
import {mapGetters} from "vuex";
import moment from "moment/moment";
import {getUserInfo} from "@/api/accountCenter";

export default {
  data() {
    return {
      versionCompanyProductMap:{},
      whitePaper:{},
    };
  },

  computed: {
    ...mapGetters("user", ["getUserInfo"]),
  },

  methods: {
    async initData() {
      try {
        const {data} = await getAllCompanyProduct({ companyId: 449 });
        this.versionCompanyProductMap = data;
      } catch (e) {
        console.log(e);
      }

    },
    async initData1(){
      try {
        const {data} = await getWhitePaper({ companyId: 449 });
        this.whitePaper = data;
      } catch (e) {
        console.log(e);
      }
    },
    formatDateTime(dateTime) {
      return moment(dateTime).format('YYYY-MM-DD HH:mm:ss');
    },
    // 跳转到指定页面
    gotoDetail(id) {
      //先校验是否登录，先登录才能下载
      getUserInfo().then(res => {
        if(res.code === 200 && res.message === 'SUCCESS') {
          /*this.$router.push({
            name: "detail",
            params: {id},
          });*/
          let routerJump = this.$router.resolve({ path: '/detail/'+id});
          window.open(routerJump.href, '_blank');
        }
      }).catch(() => {
        this.$message.error("您还未登录，请登录后下载！");
        this.$Bus.$emit('showLogin', 'show')
        return new Promise( () => {})
      });
    },
  },

  created() {
    this.initData();
    this.initData1();
  },
};
</script>
<style scoped lang="scss">
.home {
  &-banner {
    position: relative;

    img {
      width: 100%;
    }

    &-content {
      position: absolute;
      top: 140px;
      left: 360px;

      h1 {
        font-size: 48px;
        color: #333;
        margin-bottom: 40px;
      }
    }
  }
  table {
    border-collapse: collapse;
    width: 100%;

    td, th {
      border: 1px solid #ccc;
      padding: 8px;
    }

    td.left-column {
      width: 30%;
    }

    td.right-column {
      width: 70%;
    }
  }

  &-info {
    position: relative;
    z-index: 10;
    top: -50px;
    display: flex;
    justify-content: center;

    &-cell {
      width: 380px;
      height: 100px;
      display: flex;
      align-items: center;
      box-shadow: 4px 8px 8px 8px #f5f5f5;
      border-radius: 6px 6px 6px 6px;
      background-color: white;

      img {
        width: 40px;
        margin-right: 24px;
        margin-left: 24px;
      }

      .title {
        font-size: 18px;
        color: #333333;
        line-height: 35px;
      }

      .desc {
        font-size: 15px;
        color: #8791a1;
        line-height: 35px;
      }
    }

    &-cell + &-cell {
      margin-left: 24px;
    }
  }

  &-title {
    font-size: 36px;
    color: #262c33;
    line-height: 35px;
    text-align: center;
  }

  &-subtitle {
    font-size: 20px;
    color: #262c33;
    line-height: 35px;
    text-align: center;
  }

  .divider {
    text-align: center;
    margin-top: 32px;

    label {
      display: inline-block;
      width: 240px;
      height: 1px;
      background-color: #0f1010;
    }
  }

  &-resolve {
    &-banner {
      margin: 0 auto;
      width: 1200px;
      position: relative;
      text-align: center;

      img {
        width: 1200px;
      }

      &-content {
        position: absolute;
        right: 70px;
        top: 105px;
      }
    }

    &-list {
      margin: 0 auto;
      width: 1200px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &-cell {
      display: flex;
      width: 542px;
      padding: 24px;
      box-shadow: 4px 8px 8px 8px #f5f5f5;
      border-radius: 6px 6px 6px 6px;
      background-color: white;
      margin-top: 12px;

      img {
        width: 64px;
        height: 64px;
        margin-right: 24px;
      }

      .title {
        font-size: 24px;
        color: #262c33;
        line-height: 35px;
      }

      .desc {
        font-size: 16px;
        color: #262c33;
        line-height: 24px;
        margin-top: 8px;
      }

      .op {
        margin-top: 8px;

        span {
          font-size: 14px;
          color: #797b7f;
          cursor: pointer;
        }

        span + span {
          margin-left: 10px;
        }
      }

      .op1 {

        span {
          font-size: 16px;
          color: #748db1;
          cursor: pointer;
        }

        span + span {
          margin-left: 20px;
        }
      }
    }
  }

  &-select {
    width: 100%;
    margin: 54px 0;
  }

  &-news {
    &-content {
      display: flex;
      margin: 90px auto 0;
      width: 1170px;

      img {
        width: 556px;
        margin-right: 70px;
      }

      &-cell {
        padding-bottom: 40px;
        border-bottom: 1px solid #eee;

        .title {
          font-size: 22px;
          color: #262c33;
          line-height: 35px;
        }

        .desc {
          font-size: 16px;
          color: #a8abad;
          line-height: 24px;
          margin-top: 24px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }

      &-cell + &-cell {
        padding-top: 24px;
      }
    }

    &-more {
      display: flex;
      justify-content: center;
      margin-top: 42px;
      margin-bottom: 50px;

      div {
        cursor: pointer;
        padding: 8px 50px;
        color: #333;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #333;
      }
    }
  }
}
</style>
